<!--
 * @Author: your name
 * @Date: 2020-12-23 19:44:15
 * @LastEditTime: 2021-06-01 18:51:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\shareit\index.vue
-->
<template>
  <div class="allmsg" v-wechat-title="$route.meta.title">
    <navigation msg="邀请有礼"></navigation>
    <div class="qrclass">
      <qrcode
        :url="url"
        :iconurl="icon"
        :wid="88"
        :hei="88"
        :imgwid="20"
        :imghei="16"
      ></qrcode>
      <canvas id="myCanvas"></canvas>
    </div>
    <div v-show="!divsrc" class="">
      <img style="width: 100%" src="../../assets/fx3.png" alt="" />
    </div>
    <div class="divclass"><img :src="divsrc" class="divsrcclass" alt="" /></div>

    <!-- <div class="qrshareit">
      <div class="canclass"></div>
      <div class="theqrmsg">
        <div>我是{{ username }}</div>
        <div>我在使用萝卜猎手 企业用人更放心</div>
        <div>长按二维码 即刻注册使用</div>
      </div>
    </div>
    
    
    <div class="qrp"></div>
    <sharepage ref="sharitid"></sharepage>
    <share ref="shareid"></share> -->
    <div class="fexmsgclass">
      <div><span>*</span> 长按海报保存至相册,转发邀请好友领取免费体验</div>
    </div>

    <div
      v-show="sharetype == true"
      @click="$router.push('/guide')"
      class="tuiclass"
    >
      邀请指南
    </div>
    <button v-show="sharetype == true" @click="clickmeth2" class="bton bt">
      生成我的官网，去邀请
    </button>
    <sharepage ref="sharitid"></sharepage>
    <share ref="shareid"></share>
    <div class="nomsgclass"></div>
  </div>
</template>
<script>
import qrcode from "vue_qrcodes";
import fenxiang from "../../assets/fenxiang.png";
import sharepage from "../../views/sharepage/index";
import share from "../../components/share/index";
import base from "../../../util/base";
export default {
  components: {
    qrcode,
    sharepage,
    share,
  },
  data() {
    return {
      sharetype: true,
      phone: "",
      url:
        base.url +
        "/register?invitecode=" +
        localStorage.getItem("phone"),
      icon: require("../../assets/users3.png"),
      msg: "邀请有礼",
      username: localStorage.getItem("username"),
      fenxiang: fenxiang,
      canvasmsg: "",
      can: "",
      divsrc:"",
      msges:""
    };
  },
  // beforeCreate(){
  //   if(this.isreload==true){
  //     location.reload();
  //     this.isreload = false;
  //   }
  // },
  created() {
    this.msges = JSON.parse(sessionStorage.getItem("sharemsg"));
    try {
      if (this.msges.isshare && this.msges.isshare == "1") {
        this.sharetype = false;
        this.url = base.url + "/login?invitecode=" + this.msges.msg;
        this.username = this.msges.username;
      }
    } catch (e) {
      this.clickshare();
    }

    // document.addEventListener("WeixinJSBridgeReady", function onBridgeReady() {
    //   // 通过下面这个API隐藏右上角按钮
    //   WeixinJSBridge.call("hideOptionMenu");
    // });

    // document.addEventListener("WeixinJSBridgeReady", function onBridgeReady() {
    //   // 通过下面这个API显示右上角按钮
    //   WeixinJSBridge.call("showOptionMenu");
    // });
  },
  mounted() {
    this.getcode();
  },

  methods: {
    getcode() {
      this.can = document.getElementById("myCanvas");
      var img = new Image();
      this.can.width = document.documentElement.clientWidth * 2;
      this.can.height = document.documentElement.clientWidth * 2 * 1.5;
      this.canvasmsg = this.can.getContext("2d");
      img.src = require("../../assets/fx3.png");
      img.onload = () => {
        this.canvasmsg.drawImage(
          img,
          0,
          0,
          document.documentElement.clientWidth * 2,
          document.documentElement.clientWidth * 2 * 1.5
        );
        setTimeout(() => {
          this.drawimgtwometh();
        });
      };
      this.can.style =
        "  position: absolute;top:-50rem;transform-origin: 0px 0px;zoom:0.5;";
    },
    // 画第二个图片
    drawimgtwometh() {
      let qrimg = document.getElementsByClassName("qrcode")[0].childNodes[2].childNodes[0].toDataURL("image/png");

      let img2 = new Image();
      
      img2.src = qrimg;
      img2.onload = () => {
        this.canvasmsg.drawImage(
          img2,
          ((document.documentElement.clientWidth * 2) / 100) * 39.5,
          ((document.documentElement.clientWidth * 2) / 100) * 110.9,
          ((document.documentElement.clientWidth * 2) / 100) * 20,
          ((document.documentElement.clientWidth * 2) / 100) * 20
        );
        let imgurl = this.can.toDataURL("image/png");
        this.divsrc=imgurl;
      };
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    clickshare() {
      this.clickmeth();
    },
    clickmeth2() {
      this.$refs.shareid.clickshare();
    },
    childsharemeth() {
      this.clickshare();
      this.$refs.sharitid.mountedmeth();
    },
    /**
     * 点击去邀请
     */
    clickmeth() {
      let s_phone = "";
      if(this.msges){
        s_phone = this.msges.msg;
      }else{
        s_phone = localStorage.getItem("phone");
      }
      let obj = JSON.stringify({
        sharetitle: "企业招聘重要通知",
        sharedesc: "企业安全招聘指南，立即领取！",
        isshare: "1",
        msg: localStorage.getItem("phone"),
        username: localStorage.getItem("username"),
        tourlmsg:
          base.tourl + "%2F%23%2F?invitecode=" + s_phone,
        share_phone:localStorage.getItem("phone")
      });
      localStorage.setItem("shareobj", obj);
    },
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
};
</script>
<style scoped>
.divsrcclass{
  width: 100%;
}
.fexmsgclass {
  padding: 0.2rem;
  line-height: 0.4rem;
}
.fexmsgclass > span {
  color: #f55613;
}
.fexmsgclass > div {
  text-align: center;
  font-size: 0.3rem;
  color: #5e5c5c;
}
.qrclass {
  position: absolute;
  top: -50rem;
}
.van-nav-bar >>> .van-icon {
  color: #000000;
  font-size: 0.36rem;
}
.fen {
  width: 0.4rem;
  height: 0.41rem;
}
.theqrmsg {
  color: #ffffff;
  font-size: 0.25rem;
  position: absolute;
  bottom: 1rem;
  left: 0.3rem;
}
.bt {
  margin-top: 0.16rem;
}
.tuiclass {
  color: #f55613;
  font-size: 0.36rem;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
}
.allmsg {
  padding-bottom: 2rem;
  padding-bottom: 0;
  min-height: 100%;
  background-color: #ffffff;
}
.qrshareit {
  margin: 0.44rem 0.34rem;
  height: 9.97rem;
  background-image: url("../../assets/shaire.png");
  background-size: 100% 100%;
  position: relative;
}
.canclass {
  position: absolute;
  bottom: 0.94rem;
  right: 0.58rem;
  padding: 0.1rem;
  background-color: #ffffff;
}
.qrmsg {
  position: absolute;
  font-size: 0.2rem;
  line-height: 0.28rem;
  right: 0.58rem;
  bottom: 0.51rem;
  color: #ffffff;
}
.qrp {
  margin-top: 0.76rem;
  font-size: 0.34rem;
  color: #333333;
}
.qrp p {
  text-align: center;
}
.nomsgclass {
  height: 0.3rem;
}
</style>